<template>
  <div class="user-agreement-box">
      <title-nav :title="'用户协议'"/>
      <div class="text" v-for="item in privacyPolicy" :key="item.id">
        <div class="title">{{ item.title }}</div>
        <div class="content">{{  item.content  }}</div>
      </div>
  </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'

export default {
name: 'UserAgreement',
components: {
  TitleNav,
},
mounted(){
},
data:()=>{
  return {
    privacyPolicy:[{
      id: '1',
      title:'一、注册与账户管理',
      content:'用户需提供真实有效的注册信息，对账户和密码负责。用户有责任保管账户信息，禁止与他人共享账户。'
    },{
      id: '2',
      title:'二、用户行为规范',
      content:'用户不得使用自动方式访问网站，不得对网站造成不合理负荷。未经准许，不得复制、修改、分发网站内容。不得干扰网站正常运作或其他违法违规行为。'
    },{
      id: '3',
      title:'三、知识产权等权益',
      content:'公司拥有提供服务的相关权益，用户在正常使用中享有使用权。知识产权属于公司或关联公司。用户上传的原创信息知识产权归用户所有，但授予公司相关权益。'
    },{
      id: '4',
      title:'四、不可抗力和免责事由',
      content:'公司尽力确保网站正常运行，但无法保证服务不中断。公司不对信息准确性和完整性作保证，不对用户之间的交流负责。'
    },{
      id: '5',
      title:'五、违约责任',
      content:'公司有权根据违规情况采取警示、冻结、终止账号等措施。用户行为损害公司利益，公司有权追偿损失。'
    },{
      id: '6',
      title:'六、通知和广告',
      content:'用户提供有效联系方式，公司通过电子方式发出的通知即视为送达。允许公司通过各种方式向用户发送广告或宣传信息。'
    },{
      id: '7',
      title:'七、协议的终止',
      content:'用户有权通过指定方式终止协议。公司有权终止协议，终止后保留必要的权利。'
    },{
      id: '8',
      title:'八、法律适用、管辖',
      content:'本协议适用中国法律，争议解决由双方协商或法院管辖。'
    },{
      id: '9',
      title:'九、其他条款',
      content:'其他服务、隐私政策等为本协议的补充。任何无效条款不影响其他有效条款。公司可根据法规修改协议，用户继续使用即视为接受修改。'
    },{
      id: '10',
      title:'十、联系方式',
      content:'人工客服(微信)：fuwujin2022'
    }]
  }
},
methods:{
},
}
</script>

<style scoped lang="scss">
.user-agreement-box {
  min-height: 100vh;
  background: #fafafa;
  padding: 60px 15px 0;
  color: #222;
  .text {
    text-align: left;
    padding-bottom: 15px;
    word-break: break-all;
    .title {
      font-size: 18px;
      padding: 0px 0;
    }
    .content {
      padding: 10px 0;
    }
  }
}
</style>